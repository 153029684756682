import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import ConfirmDialog from 'components/ConfirmDialog';
import TextDialog from 'components/TextDialog';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { TestCaseTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  }
}));

const TestCaseList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = useState([]);
  const [toBeCreated, setToBeCreated] = useState('');
  const [toBeDel, setToBeDel] = useState(null);
  const [toBeEdit, setToBeEdit] = useState(null);
  const [toBeCopy, setToBeCopy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  });
  const [testResults, setTestResults] = useState({});
  const [runningTest, setRunningTest] = useState(null);

  const reload = () => {
    setIsLoading(true);
    api
      .get(
        `/tests?page=${pagination.page + 1}&page_size=${pagination.rowsPerPage}`
      )
      .then(({ data }) => {
        setItems(data.results);
        setPagination({ ...pagination, count: data.count });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  const addTestCase = name => {
    api
      .post('/tests/', { ...toBeCreated, name })
      .then(({ data }) => setItems(data.results))
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setToBeCreated(null));
  };

  const performDelete = () => {
    api
      .delete(`/tests/${toBeDel.id}/`)
      .then(() =>
        enqueueSnackbar(`${toBeDel.name} deleted`, { variant: 'info' })
      )
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setToBeDel(null));
  };

  const performEdit = name => {
    api
      .patch(`/tests/${toBeEdit.id}/`, { name })
      .then(() =>
        enqueueSnackbar(`${toBeEdit.name} updated`, { variant: 'info' })
      )
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setToBeEdit(null));
  };

  const performCopy = name => {
    api
      .post('/tests/', { name, data: toBeCopy.data })
      .then(({ data }) => setItems(data.results))
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setToBeCopy(null));
  };

  const runSingleTest = testCase => {
    setTestResults({ ...testResults, [testCase.id]: 'loading' });
    return api
      .get(`/tests/${testCase.id}/run/`)
      .then(({ data }) =>
        setTestResults({
          ...testResults,
          [testCase.id]: data.success ? 'success' : 'fail'
        })
      );
  };

  const runAllTests = () => setRunningTest(0);

  useEffect(() => {
    if (runningTest == null || runningTest >= items.length) {
      return;
    }
    runSingleTest(items[runningTest])
      .then(() => setRunningTest(runningTest + 1))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  }, [runningTest]);

  const onChangePage = (_, p) => setPagination({ ...pagination, page: p });
  const onChangeRowsPerPage = p =>
    setPagination({ ...pagination, rowsPerPage: p.target.value });

  useEffect(reload, []);

  return (
    <div className={classes.root}>
      <TextDialog
        title="Enter Name"
        open={toBeCreated}
        onCancel={() => setToBeCreated(null)}
        onApply={addTestCase}
      />
      <TextDialog
        title="Enter New Name"
        open={toBeEdit != null}
        onCancel={() => setToBeEdit(null)}
        onApply={performEdit}
      />
      <TextDialog
        title="Enter Name"
        applyText="Duplicate"
        open={toBeCopy != null}
        onCancel={() => setToBeCopy(null)}
        onApply={performCopy}
      />
      <ConfirmDialog
        open={toBeDel != null}
        onCancel={() => setToBeDel(null)}
        onConfirm={performDelete}
        title="Delete"
        text={`Are you sure you want to delete '${
          toBeDel ? toBeDel.name : ''
        }'?`}
      />

      <div className={classes.toolbar}>
        <span className={classes.spacer} />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={runAllTests}>
          Run All Tests
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() =>
            setToBeCreated({
              field_data: '{}',
              input_data: '[]',
              output_data: '[]'
            })
          }>
          New Test Case
        </Button>
      </div>
      <div className={classes.content}>
        <TestCaseTable
          testResults={testResults}
          isLoading={isLoading}
          pagination={{ ...pagination, onChangePage, onChangeRowsPerPage }}
          items={items}
          deleteItem={setToBeDel}
          copyItem={setToBeCopy}
          editName={setToBeEdit}
        />
      </div>
    </div>
  );
};

export default TestCaseList;
