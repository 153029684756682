import { useEffect, useRef } from 'react';

const handlers = {};

export function useChannel(channel, cb) {
  const callback = useRef(cb);
  useEffect(() => {
    callback.current = cb;
  }, [cb]);

  return useEffect(() => {
    if (!handlers[channel]) {
      handlers[channel] = [];
    }
    handlers[channel].push(callback);

    return () => {
      const index = handlers[channel].indexOf(callback);
      if (index >= 0) {
        handlers[channel] = handlers[channel].splice(index, 1);
      }
    };
  }, []);
}

// const ws = new WebSocket(`ws://127.0.0.1:8080/ws`);
const ws = new WebSocket(`wss://snej.hadi.sh/ws`);

ws.onmessage = (message) => {
  const data = JSON.parse(message.data);
  const h = handlers[data.channel];
  if (!h) {
    return;
  }
  h.forEach((l) => l.current(data.args));
};
