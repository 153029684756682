import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import api from 'api-snej';
import { useChannel } from 'push';
import React, { useEffect, useState } from 'react';

function LinearProgressWithLabel({ instance }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          color="primary"
          value={(100 * instance.tokens) / instance.max_tokens}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary">{`${instance.tokens}/${instance.max_tokens}`}</Typography>
      </Box>
    </Box>
  );
}

const TokenStats = ({ ...rest }) => {
  const [instances, setInstances] = useState([]);

  useEffect(() => {
    api
      .get(`/keepa-tokens`)
      .then((data) => {
        setInstances(data.data);
      })
      .catch((e) => console.error(e));
  }, []);

  useChannel('keepa_tokens', (instance) => {
    let found = false;
    const tt = [...instances];
    for (let o of tt) {
      if (o.api_key == instance.api_key) {
        o.tokens = instance.tokens;
        o.max_tokens = instance.max_tokens;
        found = true;
        break;
      }
    }
    if (!found) {
      tt.push(instance);
    }
    setInstances(tt);
  });

  return (
    <div {...rest}>
      {instances.map((k) => (
        <div className="mt-2" key={k.api_key}>
          <LinearProgressWithLabel instance={k} />
        </div>
      ))}
    </div>
  );
};

export default TokenStats;
