import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: 300,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '14px'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  arrowIcon: {
    color: '#aaa'
  },
  selectedItem: {
    background: '#a760b3',
    color: 'white'
  }
}));

const FieldSelector = props => {
  const {
    open,
    onApply,
    onCancel,
    customFields,
    fields,
    fieldOrder,
    selectedFields,
    title,
    applyText
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState(new Set(selectedFields));

  const select = item => {
    const changed = new Set(selected);
    changed.add(item);
    setSelected(changed);
  };
  const deselect = item => {
    const changed = new Set(selected);
    changed.delete(item);
    setSelected(changed);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={<div></div>}
        title={title}
        subheader={`${items.length} items`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map(value => {
          if (selected.has(value)) {
            return (
              <ListItem
                key={value.name}
                className={classes.selectedItem}
                role="listitem"
                button
                onClick={() => deselect(value)}>
                {value.name}
              </ListItem>
            );
          }
          return (
            <ListItem
              key={value.name}
              role="listitem"
              button
              onClick={() => select(value)}>
              {value.name}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const Transfer = () => (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}>
      <Grid item>{customList('Fields', fields)}</Grid>
      <Grid item>{customList('Custom Fields', customFields)}</Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Transfer />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onApply(Array.from(selected))}
            color="primary"
            autoFocus>
            {applyText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FieldSelector.propTypes = {
  className: PropTypes.string
};

export default FieldSelector;
