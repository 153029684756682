import { makeStyles } from '@material-ui/styles';
import api from 'api';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BuyBoxFieldEditor, ROIFieldEditor } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const RulePage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const [fieldData, setFieldData] = useState([]);
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('');

  const reload = () => {
    api
      .get(`/customfields/${id}`)
      .then(({ data }) => {
        setFieldData(JSON.parse(data.data));
        setFieldName(data.name);
        setFieldType(data.field_type);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const save = () => {
    api
      .patch(`/customfields/${id}/`, {
        name: fieldName,
        data: JSON.stringify(fieldData)
      })
      .then(() =>
        enqueueSnackbar(`Rule ${fieldName} saved`, { variant: 'success' })
      )
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  useEffect(reload, []);

  let editor = () => {
    switch (fieldType) {
      case 'buybox':
        return (
          <BuyBoxFieldEditor
            value={fieldData}
            onChange={setFieldData}
            save={save}
            fieldName={fieldName}
          />
        );
      case 'roi':
        return (
          <ROIFieldEditor
            value={fieldData}
            onChange={setFieldData}
            save={save}
            fieldName={fieldName}
          />
        );
    }
    return null;
  };
  return <div className={classes.root}>{editor()}</div>;
};

export default RulePage;
