import MomentUtils from '@date-io/moment';
import { HotColumn, HotTable } from '@handsontable/react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BuyBoxFieldEditor } from '../Fields/components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  testName: {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: '0 24px'
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  loader: {
    position: 'fixed',
    bottom: 40,
    right: 40
  },
  addRowBtn: {
    color: 'blue',
    cursor: 'pointer',
    '&:hover': {
      color: 'red'
    }
  },
  sheet: {
    display: 'inline-block',
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '10px'
  },
  sheetContainer: {
    padding: '0 24px'
  },
  sheetTitle: {
    fontWeight: 'bold',
    marginLeft: '3px',
    marginBottom: '10px'
  },
  mockDateContainer: {
    padding: '0 35px',
    marginTop: '20px'
  },
  mockDate: {
    marginRight: '10px'
  }
}));

const TestCasePage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const [testName, setTestName] = useState('');
  const [fieldData, setFieldData] = useState([]);
  const [inputData, setInputData] = useState([]);
  const [expectedData, setExpectedData] = useState([]);
  const [outputData, setOutputData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [runResult, setRunResult] = useState(null);
  const [mockDate, setMockDate] = useState(null);
  const [mockTime, setMockTime] = useState(null);

  const inputSheet = React.createRef();
  const expectedSheet = React.createRef();
  const outputSheet = React.createRef();

  const reload = () => {
    setIsLoading(true);
    api
      .get(`/tests/${id}`)
      .then(({ data }) => {
        setFieldData(JSON.parse(data.field_data));
        setInputData(JSON.parse(data.input_data));
        setExpectedData(JSON.parse(data.output_data));
        if (data.mocked_time) {
          setMockDate(moment(data.mocked_time) || null);
          setMockTime(moment(data.mocked_time) || null);
        } else {
          setMockDate(null);
          setMockTime(null);
        }
        setTestName(data.name);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  const save = skipSnack => {
    const clean = values => {
      values = values.filter(v => !v.every(x => x == null));
      if (values.some(v => v.some(x => x == null))) {
        return null;
      }
      return values;
    };

    const input = clean(inputSheet.current.hotInstance.getData());
    const expected = clean(expectedSheet.current.hotInstance.getData());

    if (input == null || expected == null) {
      enqueueSnackbar('Invalid data: make sure you fill all the fields', {
        variant: 'error'
      });
      return;
    }

    if (mockDate != null && mockTime != null) {
      mockDate.hours(mockTime.hours());
      mockDate.minutes(mockTime.minutes());
    }
    setIsLoading(true);
    return api
      .patch(`/tests/${id}/`, {
        name: testName,
        field_data: JSON.stringify(fieldData),
        input_data: JSON.stringify(input),
        output_data: JSON.stringify(expected),
        mocked_time: mockDate ? mockDate.format('YYYY-MM-DDTHH:MM') : null
      })
      .then(() =>
        !skipSnack
          ? enqueueSnackbar(`Test ${testName} saved`, { variant: 'success' })
          : null
      )
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  const runTest = () => {
    save(true)
      .then(() => setIsLoading(true))
      .then(() => api.get(`/tests/${id}/run/`))
      .then(({ data }) => {
        setRunResult(data.success);
        if (data.success) {
          enqueueSnackbar(`Test ${testName} successfull`, {
            variant: 'success'
          });
        } else {
          enqueueSnackbar(`Test ${testName} failed`, { variant: 'warning' });
        }
        setOutputData(data.result);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  useEffect(reload, []);
  const handleTabChange = (e, tab) => setCurrentTab(tab);

  const handleAutoFill = (ref, start, end, values) => {
    const date = moment(values[0], 'MM/DD/YYYY');
    if (!date.isValid() || start.col !== end.col) {
      return;
    }
    for (let r = start.row; r <= end.row; r++) {
      date.add(1, 'days');
      ref.current.hotInstance.setDataAtCell(
        r,
        start.col,
        date.format('MM/DD/YYYY')
      );
    }
  };

  const renderSheet = (title, data, ref, readonly) => {
    for (let i = 0; i < 100 - data.length; i++) {
      data.push([]);
    }
    return (
      <div className={classes.sheet}>
        <div className={classes.sheetTitle}>{title}</div>
        <HotTable
          ref={ref}
          data={data}
          colHeaders={true}
          rowHeaders={false}
          manualColumnResize={true}
          width="350"
          height="500"
          contextMenu={true}
          readOnly={!!readonly}
          afterAutofill={(a, b, c) => handleAutoFill(ref, a, b, c)}>
          <HotColumn
            title="Date"
            width="120"
            type="date"
            dateFormat="MM/DD/YYYY"
            correctFormat={true}
          />
          <HotColumn
            title="Time"
            width="80"
            type="time"
            timeFormat="H:mm"
            correctFormat={true}
          />
          <HotColumn
            title="Seller"
            width="80"
            type="dropdown"
            source={['Amazon', 'Other']}
          />
          <HotColumn
            title="Price"
            width="60"
            type="numeric"
            numericFormat={{ pattern: '$ 0,0.00' }}
          />
        </HotTable>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <span className={classes.testName}>{testName}</span>
        <span className={classes.spacer} />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={runTest}>
          Run Test
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => save()}>
          Save
        </Button>
      </div>
      <div className={classes.content}>
        {isLoading && <CircularProgress className={classes.loader} size={32} />}
        {/* <Tabs value={currentTab} onChange={handleTabChange} > */}
        {/*   <Tab label="Rules" /> */}
        {/*   <Tab label="Data"/> */}
        {/* </Tabs> */}
        <div style={{ position: 'relative' }}>
          <BuyBoxFieldEditor
            value={fieldData}
            onChange={setFieldData}
            fieldName=""
          />

          <div className={classes.sheetContainer}>
            {renderSheet('Input', inputData, inputSheet)}
            {renderSheet('Expected Output', expectedData, expectedSheet)}
            {renderSheet('Actual Output', outputData, outputSheet, true)}
          </div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.mockDateContainer}>
              <KeyboardDatePicker
                className={classes.mockDate}
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                label="Mock Date"
                value={mockDate}
                onChange={setMockDate}
              />
              <KeyboardTimePicker
                margin="normal"
                label="Mock Time"
                value={mockTime}
                onChange={setMockTime}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

export default TestCasePage;
