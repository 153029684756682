import {
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import { StepSelector } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  productName: {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  loader: {
    position: 'fixed',
    bottom: 40,
    right: 40
  },
  recordCount: {
    marginTop: '20px',
    marginLeft: '40px'
  },
  recordCountLabel: {
    marginRight: '10px'
  },
  recordCountValue: {},
  recordTable: {
    maxHeight: 440
  }
}));

const ProductPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [rules, setRules] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [steps, setSteps] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [stepFilter, setStepFilter] = useState('');
  const [maxPrice, setMaxPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const loadRecords = () => {
    api
      .get(`/products/${id}?fields=title,asin,records&page_size=1000`)
      .then(({ data }) => {
        setMaxPrice(
          1.2 * data.records.reduce((a, b) => Math.max(a, b.price), 0)
        );
        delete data.records;
        setProduct(data);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const updateChart = records => {
    const series = {};
    records.forEach(r => {
      let seriesName = r.seller;
      if (r.is_buybox) {
        seriesName += '-buybox';
      }
      if (!series[seriesName]) {
        series[seriesName] = [];
      }
      series[seriesName].push([r.time, r.price]);
    });

    const chartSeries = Object.keys(series).map(s => ({
      name: s,
      type: 'scatter',
      data: series[s]
    }));
    setChartSeries(chartSeries);
    records.reverse();
    setRecords(records);
  };

  const loadRules = () => {
    api
      .get(`/customfields?page_size=100`)
      .then(({ data }) => setRules(data.results))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const loadSteps = () => {
    if (!rules.length) {
      return;
    }
    setIsLoading(true);
    const ruleMap = {};
    rules.forEach(r => {
      ruleMap[`s__${r.id}`] = r;
    });

    const rs = rules.map(r => `s__${r.id}`);
    api
      .get(`/products/${id}?fields=records,${rs}&record_filter=${stepFilter}`)
      .then(({ data }) => {
        updateChart(data.records);
        delete data.records;
        return data;
      })
      .then(data =>
        Object.keys(data).map(rId => ({
          name: ruleMap[rId].name,
          id: ruleMap[rId].id,
          steps: data[rId]
        }))
      )
      .then(x => setSteps(x))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  };

  const handleStepSelect = filter => {
    if (filter === 'all') {
      filter = '';
    }
    setStepFilter(filter);
  };

  const handleTabChange = (e, tab) => setCurrentTab(tab);

  useEffect(loadRules, []);
  useEffect(loadRecords, []);
  useEffect(loadSteps, [rules, stepFilter]);

  const chartOptions = {
    chart: {
      type: 'line',
      id: 'sasori',
      animations: {
        enabled: false
      },
      width: '100%'
    },
    markers: {
      size: 2,
      strokeWidth: 0
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: false
    },
    xaxis: {
      type: 'datetime'
    },
    stroke: {
      show: true,
      curve: 'stepline',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0
    },
    yaxis: { max: maxPrice }
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <span className={classes.productName}>{product.title}</span>
        <span className={classes.spacer} />
        <span className={classes.productName}>{product.asin}</span>
        {/* <Button className={classes.button} variant="contained">Select Fields</Button> */}
      </div>
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <StepSelector steps={steps} onSelect={handleStepSelect} />
          </Grid>
          <Grid item md={8} xs={12}>
            {isLoading && (
              <CircularProgress className={classes.loader} size={32} />
            )}
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label="Chart" />
              <Tab label="Table" />
            </Tabs>
            <div style={{ position: 'relative' }}>
              {currentTab === 0 && (
                <div>
                  {maxPrice !== 0 && (
                    <Chart options={chartOptions} series={chartSeries} />
                  )}
                </div>
              )}

              {currentTab === 1 && (
                <div>
                  <TableContainer className={classes.recordTable}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell> Date </TableCell>
                          <TableCell> Time From Now</TableCell>
                          <TableCell> Seller </TableCell>
                          <TableCell> Price </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.map(record => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={record.id}>
                              <TableCell>
                                {moment(record.time)
                                  .utc()
                                  .format('lll')}
                              </TableCell>
                              <TableCell>
                                {moment(record.time).fromNow()}
                              </TableCell>
                              <TableCell>{record.seller}</TableCell>
                              <TableCell>{record.price}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              {!isLoading && (
                <div className={classes.recordCount}>
                  <span className={classes.recordCountLabel}>
                    Record Count:
                  </span>
                  <span className={classes.recordCountValue}>
                    {records.length}
                  </span>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProductPage;
