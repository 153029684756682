import axios from 'axios';

// export const baseURL = 'http://localhost:8000'
export const baseURL =
  process.env.REACT_APP_SNEJ_API || 'http://localhost:8080';

const client = axios.create({
  baseURL,
  timeout: 60000
});

client.interceptors.response.use(
  (r) => r,
  (error) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      window.location = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default client;
