import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px',
    background: 'black',
    color: 'white'
  },
  content: {
    padding: '20px'
  },
  actions: {
    padding: '20px',
    justifyContent: 'flex-end'
  },
  chipSuccess: {
    background: green[400],
    color: 'white'
  },
  chipProgress: {
    // background: red[400],
    // color: 'white',
    width: '58px',
    textAlign: 'center'
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#aaa',
    '&:hover': {
      color: red[300]
    },
    '&:active': {
      color: red[400]
    }
  },
  domainSelect: {
    marginLeft: '35px',
    width: '120px'
  },
  logs: {
    maxHeight: '600px',
    overflowY: 'auto',
    fontFamily: "'Courier New', monospace",
    fontSize: '12px'
  }
}));

const Logs = props => {
  const { className, onCreate, ...rest } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const logRef = React.createRef();
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    'wss://jensws.hadi.sh'
  );
  const [data, setData] = useState([]);
  const [restartDisabled, setRestartDisabled] = useState(false);

  const restart = () => {
    console.log('RESTARTINGGGGGGGGGGGG');
    setRestartDisabled(true);
    sendMessage('restart');
  };

  useEffect(() => {
    const logs = logRef.current;
    if (lastMessage && logs) {
      console.log(lastMessage.data);
      if (lastMessage.data === 'restarted') {
        setRestartDisabled(false);
      } else {
        lastMessage.data.split('\n').forEach(d => {
          const line = document.createElement('div');
          line.innerHTML = d;
          logs.appendChild(line);
        });
        logs.scrollTo({ top: logs.scrollHeight, smooth: true });
      }
      // setData([...data, ...lastMessage.data.split('\n')])
    }
  }, [lastMessage]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div id="logs" ref={logRef} className={classes.logs}></div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={restart}
          disabled={restartDisabled}>
          Restart
        </Button>
      </CardActions>
    </Card>
  );
};

Logs.propTypes = {
  className: PropTypes.string
};

export default Logs;
