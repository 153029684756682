import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldSelector, Table } from '../Products/components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  prepareDownloadLabel: {
    fontSize: '14px',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    marginRight: '20px'
  }
}));

const _c = (name, fields, creator, isDefault) => ({
  name,
  fields,
  creator,
  isDefault
});

const allFields = [
  _c(
    'Image',
    ['product_image'],
    t => <img alt="" width="100" src={t.product_image} />,
    true
  ),
  _c(
    'Title',
    ['title'],
    t => <a href={`/products/${t.id}`}>{t.title}</a>,
    true
  ),
  _c(
    'ASIN',
    ['asin', 'amazon_url'],
    t => <a href={t.amazon_url}>{t.asin}</a>,
    true
  ),
  _c(
    'Source',
    ['source', 'source_url'],
    t => <a href={t.source_url}>{t.source}</a>,
    true
  ),
  _c(
    'Records',
    ['amazon_count', 'new_count', 'used_count', 'listprice_count'],
    t => `${t.amazon_count + t.listprice_count}`,
    true
  ),
  _c('Brand', ['brand'], t => t.brand),
  _c('UPC/EAN', ['upc_ean'], t => t.upc_ean),
  _c('Sales Rank', ['sales_rank'], t => t.sales_rank, true),
  _c('Monthly Sales', ['monthly_sales'], t => t.monthly_sales, true),
  _c('Review Count', ['review_count'], t => t.review_count, true),
  _c('Rating', ['rating'], t => t.rating, true),
  _c('Weight (lb)', ['weight'], t => t.weight, true),
  _c('Price', ['price'], t => t.price, true),
  _c('Size Class', ['size'], t => t.size, true)
];
const fieldOrder = {};
allFields.forEach((f, idx) => (fieldOrder[f.name] = idx + 1));

const prevSelectedFields = new Set(
  (
    localStorage.getItem('selected_fields') ||
    allFields
      .filter(f => f.isDefault)
      .map(f => f.name)
      .join(',')
  ).split(',')
);
console.log(prevSelectedFields);

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ProductSetList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [fieldSelectorOpen, setFieldSelectorOpen] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(null);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  // const [selectedFields, setSelectedFields] = useState(allFields.filter(f => f.isDefault));
  const [selectedFields, setSelectedFields] = useState(
    allFields.filter(f => prevSelectedFields.has(f.name))
  );
  const [fields, setFields] = useState(allFields);
  const [customFields, setCustomFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  });
  const [rulesLoaded, setRulesLoaded] = useState(false);

  const reload = () => {
    if (!rulesLoaded) {
      return;
    }
    setIsLoading(true);
    const fields = selectedFields
      .map(f => f.fields)
      .flat()
      .join(',');
    api
      .get(
        `/products?page=${pagination.page + 1}&page_size=${
          pagination.rowsPerPage
        }&productset=${id}&fields=id,${fields}`
      )
      .then(({ data }) => {
        setItems(data.results);
        setPagination({ ...pagination, count: data.count });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  const loadRules = () => {
    api
      .get(`/customfields?page_size=100`)
      .then(({ data: { results } }) =>
        results.map(r => _c(r.name, [`r__${r.id}`], t => t[`r__${r.id}`]))
      )
      .then(fields => {
        setCustomFields(fields);
        setSelectedFields([
          ...selectedFields,
          ...fields.filter(f => prevSelectedFields.has(f.name))
        ]);
        setRulesLoaded(true);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const onChangePage = (_, p) => setPagination({ ...pagination, page: p });
  const onChangeRowsPerPage = p =>
    setPagination({ ...pagination, rowsPerPage: p.target.value });
  const onFieldsChanged = fields => {
    setSelectedFields(fields);
    setFieldSelectorOpen(false);
    localStorage.setItem('selected_fields', fields.map(f => f.name).join(','));
  };

  const startDownloadCSV = selectedFields => {
    setDownloadingProgress(0);
    setDownloadingCSV('progress');
    api
      .get(
        `/product_csv?ps=${id}&fields=${selectedFields
          .map(f => f.fields)
          .flat()
          .join(',')}`
      )
      .then(({ data }) => {
        const taskId = data.task_id;

        return new Promise((resolve, reject) => {
          const request = () => {
            api
              .get(`/downloads/${taskId}`)
              .then(({ data }) => {
                setDownloadingProgress(
                  Math.round((100 * data.progress) / data.count)
                );
                if (!data.done) {
                  return setTimeout(request, 2000);
                } else {
                  return resolve(data.file);
                }
              })
              .catch(e => reject(e));
          };
          setTimeout(request, 2000);
        });
      })
      .then(url => {
        setDownloadingCSV(null);
        window.location = url;
      })
      .catch(e => {
        setDownloadingCSV(null);
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  useEffect(reload, [
    pagination.page,
    pagination.rowsPerPage,
    selectedFields,
    rulesLoaded
  ]);
  useEffect(loadRules, []);

  return (
    <div className={classes.root}>
      {fieldSelectorOpen && (
        <FieldSelector
          title="Select Fields"
          applyText="Apply"
          customFields={customFields}
          fields={fields}
          fieldOrder={fieldOrder}
          selectedFields={selectedFields}
          open={fieldSelectorOpen}
          onCancel={() => setFieldSelectorOpen(false)}
          onApply={onFieldsChanged}
        />
      )}
      {downloadingCSV == 'fields' && (
        <React.Fragment>
          <FieldSelector
            title="Select Fields to Download"
            applyText="Download"
            customFields={customFields}
            fields={fields}
            fieldOrder={fieldOrder}
            selectedFields={selectedFields}
            open={downloadingCSV}
            onCancel={() => setDownloadingCSV(null)}
            onApply={startDownloadCSV}
          />
        </React.Fragment>
      )}
      <div className={classes.toolbar}>
        <span className={classes.spacer} />
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => setFieldSelectorOpen(true)}>
          Select Fields
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={!!downloadingCSV}
          onClick={() => setDownloadingCSV('fields')}>
          Download CSV
        </Button>
        {downloadingCSV === 'progress' && (
          <div>
            <CircularProgressWithLabel
              withlabel
              variant="determinate"
              value={downloadingProgress}
            />
          </div>
        )}
      </div>
      <div className={classes.content}>
        <Table
          items={items}
          isLoading={isLoading}
          fields={selectedFields}
          pagination={{ ...pagination, onChangePage, onChangeRowsPerPage }}
          deleteItem={() => {}}
        />
      </div>
    </div>
  );
};

export default ProductSetList;
