import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import validate from 'validate.js';
import './assets/css/index.css';
import validators from './common/validators';
import Routes from './Routes';
import theme from './theme';
const browserHistory = createBrowserHistory();

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    );
  }
}
