import { Button, Paper, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  form: {
    maxWidth: '400px',
    padding: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  }
}));

const ROIFieldEditor = props => {
  const { value: fieldData, onChange, save, fieldName } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [buyboxFields, setBuyBoxFields] = useState([]);

  const reload = () => {
    api
      .get('/buyboxfields')
      .then(({ data }) => setBuyBoxFields(data.results))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const onPercentChange = (f, v) => {
    if (v === '') {
      return onChange({ ...fieldData, [f]: undefined });
    }
    v = parseInt(v);
    if (!isNaN(v) && v <= 100 && v >= 0) {
      onChange({ ...fieldData, [f]: v });
    }
  };

  const onPriceChange = (f, v) => {
    if (v === '') {
      return onChange({ ...fieldData, [f]: undefined });
    }
    const val = parseFloat(v);
    if (!isNaN(val) && val >= 0) {
      onChange({ ...fieldData, [f]: v });
    }
  };

  useEffect(reload, []);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <span className={classes.ruleName}>{fieldName}</span>
        <span className={classes.spacer} />
        <Button className={classes.button} variant="contained" onClick={save}>
          Save
        </Button>
      </div>
      <Paper className={classes.content}>
        <div className={classes.form}>
          <Select
            native
            fullWidth
            label="BuyBox Field"
            margin="dense"
            value={fieldData.buybox_field}
            onChange={e =>
              onChange({ ...fieldData, buybox_field: e.target.value })
            }
            variant="outlined">
            <option value="">BuyBox Field</option>
            {buyboxFields.map(f => (
              <option key={f.id} value={f.id}>
                {f.name}
              </option>
            ))}
          </Select>
          <TextField
            fullWidth
            label="Referrral Fee (%)"
            margin="dense"
            onChange={e => onPercentChange('referral_fee', e.target.value)}
            value={fieldData.referral_fee == null ? '' : fieldData.referral_fee}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Tax (%)"
            margin="dense"
            onChange={e => onPercentChange('tax', e.target.value)}
            value={fieldData.tax == null ? '' : fieldData.tax}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Shipping Cost"
            margin="dense"
            onChange={e => onPriceChange('shipping_cost', e.target.value)}
            value={
              fieldData.shipping_cost == null ? '' : fieldData.shipping_cost
            }
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Preparation Cost"
            margin="dense"
            onChange={e => onPriceChange('preparation_cost', e.target.value)}
            value={
              fieldData.preparation_cost == null
                ? ''
                : fieldData.preparation_cost
            }
            variant="outlined"
          />
          <Select
            native
            fullWidth
            label="Period"
            margin="dense"
            value={fieldData.period}
            onChange={e => onChange({ ...fieldData, period: e.target.value })}
            variant="outlined">
            <option value={'first'}>January - September</option>
            <option value={'second'}>September - January</option>
          </Select>
        </div>
      </Paper>
    </div>
  );
};

export default ROIFieldEditor;
