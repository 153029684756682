import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';

const TextDialog = ({
  title,
  open,
  onCancel,
  onApply,
  applyText,
  cancelText
}) => {
  const [value, setValue] = useState('');
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField value={value} onChange={e => setValue(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            onApply(value);
            setValue('');
          }}
          color="primary"
          autoFocus>
          {applyText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TextDialog.defaultProps = {
  title: '',
  onCancel: () => {},
  onApply: () => {},
  applyText: 'Apply',
  cancelText: 'Cancel'
};

export default TextDialog;
