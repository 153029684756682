import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const KeepaSettings = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [apiKey, setAPIKey] = useState('');
  const [syncTolerance, setSyncTolerance] = useState('');

  const reload = () => {
    Promise.all([
      api.get('/config/keepa_api_key/').then(({ data: { value } }) => value),
      api
        .get('/config/keepa_sync_tolerance/')
        .then(({ data: { value } }) => value)
    ])
      .then(([apiKey, syncTolerance]) => {
        console.log(apiKey, syncTolerance);
        setAPIKey(apiKey);
        setSyncTolerance(syncTolerance);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };
  const save = () => {
    Promise.all([
      api.patch('/config/keepa_api_key/', { value: apiKey }),
      api.patch('/config/keepa_sync_tolerance/', { value: syncTolerance })
    ])
      .then(() =>
        enqueueSnackbar('Settings updated successfully', { variant: 'success' })
      )
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  useEffect(reload, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="Manage keepa settings" title="Keepa" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            multiline="true"
            // helperText="Please specify the first name"
            label="Keep API Key"
            margin="dense"
            name="firstName"
            onChange={e => setAPIKey(e.target.value)}
            required
            value={apiKey}
            variant="outlined"
          />
          <TextField
            fullWidth
            // helperText="Please specify the first name"
            label="Sync out-of-date tolerance"
            margin="dense"
            name="firstName"
            onChange={e => setSyncTolerance(e.target.value)}
            required
            value={syncTolerance}
            type="number"
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={save}>
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

KeepaSettings.propTypes = {
  className: PropTypes.string
};

export default KeepaSettings;
