import { Drawer } from '@material-ui/core';
import PriceIcon from '@material-ui/icons/AttachMoney';
import TestsIcon from '@material-ui/icons/DoneAll';
import KeepaIcon from '@material-ui/icons/Poll';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RulesIcon from '@material-ui/icons/Tune';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import TokenStats from 'components/TokenStats';
import PropTypes from 'prop-types';
import React from 'react';
import { SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      limited: true,
      title: 'Products',
      href: '/productsets',
      icon: <ShoppingBasketIcon />
    },
    {
      limited: true,
      title: 'Fields',
      href: '/fields',
      icon: <RulesIcon />
    },
    {
      limited: true,
      title: 'Tests',
      href: '/tests',
      icon: <TestsIcon />
    },
    {
      limited: true,
      title: 'Keepa',
      href: '/keepa',
      icon: <KeepaIcon />
    },
    {
      limited: false,
      title: 'Prices',
      href: '/hunts',
      icon: <PriceIcon />
    },
    {
      limited: true,
      title: 'Sheets',
      href: '/sheets',
      icon: <ViewColumnIcon />
    },
    {
      limited: false,
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Profile /> */}
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav className={classes.nav} pages={pages.filter(page => !page.limited || localStorage.getItem('jens_username') === 'admin')} />
        {/* <UpgradePlan /> */}
      </div>

      <TokenStats className="p-8"></TokenStats>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
