import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue, green, red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  ruleNameCol: {
    width: '350px'
  },
  deleteIcon: {
    cursor: 'pointer',
    verticalAlign: 'middle',
    color: '#aaa',
    '&:hover': {
      color: red[300]
    },
    '&:active': {
      color: red[400]
    }
  },
  copyIcon: {
    cursor: 'pointer',
    marginRight: '20px',
    verticalAlign: 'middle',
    color: '#aaa',
    '&:hover': {
      color: blue[300]
    },
    '&:active': {
      color: blue[400]
    }
  },
  editIcon: {
    cursor: 'pointer',
    marginLeft: '20px',
    verticalAlign: 'middle',
    color: green[200],
    '&:hover': {
      color: green[300]
    },
    '&:active': {
      color: green[400]
    }
  }
}));

const RuleTable = props => {
  const {
    className,
    items,
    isLoading,
    deleteItem,
    copyItem,
    editName,
    pagination,
    ...rest
  } = props;

  const classes = useStyles();
  const [editableRow, setEditableRow] = useState(null);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Rule Count</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(item => (
                  <TableRow className={classes.tableRow} hover key={item.id}>
                    <TableCell
                      className={classes.ruleNameCol}
                      onMouseEnter={() => {
                        setEditableRow(item.id);
                      }}
                      onMouseLeave={() => {
                        if (editableRow === item.id) setEditableRow(null);
                      }}>
                      <div>
                        <a href={'/fields/' + item.id}>{item.name}</a>
                        {editableRow === item.id && (
                          <EditIcon
                            onClick={() => editName(item)}
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{JSON.parse(item.data).length}</TableCell>
                    <TableCell>{moment(item.created_at).fromNow()}</TableCell>
                    <TableCell>{moment(item.updated_at).fromNow()}</TableCell>
                    <TableCell>
                      <CopyIcon
                        fontSize="small"
                        className={classes.copyIcon}
                        onClick={() => copyItem(item)}
                      />
                      <DeleteIcon
                        className={classes.deleteIcon}
                        onClick={() => deleteItem(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        {isLoading && <CircularProgress size={24} />}
        <TablePagination component="div" {...pagination} />
      </CardActions>
    </Card>
  );
};

RuleTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array
};
RuleTable.defaultProps = {
  items: []
};

export default RuleTable;
