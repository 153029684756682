import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400
  }
});

const StepSelector = ({ steps, onSelect }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    if (nodeIds) {
      onSelect(nodeIds);
    }
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}>
      <TreeItem nodeId={'all'} label="All" />
      {steps.map(f => (
        <TreeItem nodeId={`${f.id}`} key={`${f.id}`} label={f.name}>
          {f.steps.map((r, idx) => (
            <TreeItem
              nodeId={`${f.id}-${idx}`}
              key={`${f.id}-${idx}`}
              label={r.name}>
              {r.steps.map((s, sidx) => (
                <TreeItem
                  nodeId={`${f.id}-${idx}-${sidx}`}
                  key={`${f.id}-${idx}-${sidx}`}
                  label={s}></TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeView>
  );
};

StepSelector.propTypes = {
  className: PropTypes.string
};

export default StepSelector;
