import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import clsx from 'clsx';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const XToolbar = props => {
  const { className, reload, ...rest } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSave = files => {
    const formData = new FormData();
    Promise.all(
      files.map(file => {
        formData.append('file', file);
        formData.append(
          'name',
          file.name
            .split('.')
            .slice(0, -1)
            .join('.')
        );
        return api.post('/productsets/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      })
    )
      .then(() => enqueueSnackbar('Upload successful', { variant: 'success' }))
      .then(() => setOpen(false))
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}>
          Upload New Set
        </Button>
      </div>
      <DropzoneDialog
        open={open}
        submitButtonText="Upload"
        dialogTitle="Select File"
        onSave={onSave}
        acceptedFiles={['text/csv']}
        previewText={'Selected Files'}
        showPreviews={true}
        useChipsForPreview={true}
        showFileNamesInPreview={true}
        showFileNames={true}
        showAlerts={true}
        maxFileSize={10000000}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

XToolbar.propTypes = {
  className: PropTypes.string
};

export default XToolbar;
