import { makeStyles } from '@material-ui/styles';
import api from 'api';
import ConfirmDialog from 'components/ConfirmDialog';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Table, Toolbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ProductSetList = () => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [toBeDel, setToBeDel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  });

  const reload = () => {
    setIsLoading(true);
    api
      .get(
        `/productsets?page=${pagination.page + 1}&page_size=${
          pagination.rowsPerPage
        }`
      )
      .then(({ data }) => {
        setItems(data.results);
        setPagination({ ...pagination, count: data.count });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(setIsLoading);
  };

  const performDelete = () => {
    api
      .delete(`/productsets/${toBeDel.id}/`)
      .then(() =>
        enqueueSnackbar(`${toBeDel.name} deleted`, { variant: 'info' })
      )
      .then(reload)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setToBeDel(null));
  };

  const onChangePage = (_, p) => setPagination({ ...pagination, page: p });
  const onChangeRowsPerPage = p =>
    setPagination({ ...pagination, rowsPerPage: p.target.value });

  useEffect(reload, [pagination.page, pagination.rowsPerPage]);

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={toBeDel != null}
        onCancel={() => setToBeDel(null)}
        onConfirm={performDelete}
        title="Delete Product Set?"
        text={`Are you sure you want to delete product set '${
          toBeDel ? toBeDel.name : ''
        }'?`}
      />
      <Toolbar reload={reload} />
      <div className={classes.content}>
        <Table
          items={items}
          isLoading={isLoading}
          deleteItem={setToBeDel}
          pagination={{ ...pagination, onChangePage, onChangeRowsPerPage }}
        />
      </div>
    </div>
  );
};

export default ProductSetList;
