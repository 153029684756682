import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  chipSuccess: {
    background: green[400],
    color: 'white'
  },
  chipWorking: {
    background: red[400],
    color: 'white'
  },
  deleteIcon: {
    cursor: 'pointer',
    color: red[200],
    '&:hover': {
      color: red[500]
    },
    '&:active': {
      color: red[600]
    }
  },
  bigCell: {
    minWidth: '500px'
  }
}));

const XTable = props => {
  const {
    className,
    items,
    isLoading,
    fields,
    deleteItem,
    pagination,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableContainer overflowX="auto">
              <Table>
                <TableHead>
                  <TableRow>
                    {fields.map(f => (
                      <TableCell key={f.name}>{f.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => (
                    <TableRow className={classes.tableRow} hover key={item.id}>
                      {fields.map(f => (
                        <TableCell
                          key={f.name}
                          className={f.name == 'Title' ? classes.bigCell : ''}>
                          {f.creator(item)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        {isLoading && <CircularProgress size={24} />}
        <TablePagination component="div" {...pagination} />
      </CardActions>
    </Card>
  );
};

XTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default XTable;
