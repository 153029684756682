import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';
import CopyIcon from '@material-ui/icons/FileCopy';
import WeightIcon from '@material-ui/icons/FitnessCenter';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import arrayMove from 'array-move';
import { useSnackbar } from 'notistack';
// import 'prismjs/components/prism-clike';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Editor from 'react-simple-code-editor';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '20px 10px'
  },
  inner: {
    minWidth: 1050
  },
  ruleItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    cursor: 'move',
    padding: '10px 0',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(220,220,240,10)'
    }
  },
  subruleItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    cursor: 'move',
    padding: '7px 5px',
    width: '100%',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(240,220,220,10)',
      '& > .deleteIcon': {
        visibility: 'visible'
      }
    },
    '& > .deleteIcon': {
      visibility: 'hidden'
    }
  },
  subRuleLabel: {
    verticalAlign: 'middle',
    marginBottom: '10px',
    marginRight: '5px',
    fontWeight: '500',
    fontSize: '14px'
  },
  rulePart: {
    verticalAlign: 'middle',
    padding: '0 8px',
    marginBottom: '10px'
  },
  ruleParam: {
    verticalAlign: 'middle',
    padding: '0 5px',
    marginBottom: '10px',
    width: '70px',
    '& input': {
      padding: '2px 0 2px',
      textAlign: 'center'
    }
  },
  ruleParamSelect: {
    padding: '0 5px',
    marginBottom: '10px',
    marginLeft: '5px',
    minWidth: '50px',
    '& > select': {
      padding: '2px 0 2px',
      textAlign: 'center'
    }
  },
  newRuleSelect: {
    padding: '0 5px',
    marginBottom: '10px',
    marginLeft: '35px',
    marginTop: '5px',
    width: '150px',
    fontSize: '13px',
    opacity: '0.5',
    float: 'right',
    '&:hover': {
      opacity: 1
    },
    '&  select': {
      padding: '2px 0 2px',
      textAlign: 'center'
    }
  },
  ruleIdx: {
    verticalAlign: 'middle',
    marginBottom: '10px',
    width: '10px',
    padding: '0 20px 0 10px',
    fontWeight: 'bold'
  },
  deleteIcon: {
    cursor: 'pointer',
    marginBottom: '10px',
    color: '#aaa',
    marginRight: '10px',
    '&:hover': {
      color: red[300]
    },
    '&:active': {
      color: red[400]
    }
  },
  copyIcon: {
    cursor: 'pointer',
    marginBottom: '10px',
    color: '#aaa',
    marginRight: '5px',
    '&:hover': {
      color: green[300]
    },
    '&:active': {
      color: green[400]
    }
  },
  weightIcon: {
    cursor: 'pointer',
    color: '#aaa',
    margin: '0 5px',
    '&:hover': {
      color: green[300]
    },
    '&:active': {
      color: green[400]
    }
  },
  modeSwitchIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '4px',
    right: '10px',
    color: '#aaa',
    '&:hover': {
      color: blue[300]
    },
    '&:active': {
      color: blue[400]
    }
  },
  dayRangeParam: {
    verticalAlign: 'middle',
    padding: '0 2px',
    marginBottom: '7px',
    width: '50px',
    '& input': {
      padding: '2px 0 2px',
      textAlign: 'center'
    },
    fontSize: '12px'
  },
  dayRangeLabel: {
    verticalAlign: 'middle',
    padding: '0 8px',
    fontSize: '12px'
  },
  dayRangeBtn: {
    fontSize: '12px',
    color: blue[300],
    '&:hover': {
      color: blue[400]
    },
    '&:active': {
      color: blue[500]
    },
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px'
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  ruleName: {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '5px'
  },

  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  }
}));

function subRuleName(idx) {
  return String.fromCharCode(97 + idx);
}

const DayRange = ({ ranges = [[0, 1]], updateRanges }) => {
  const classes = useStyles();

  let weighted = !(
    (ranges.length === 1 && ranges[0][1] === 1) ||
    (ranges.length === 2 && ranges[0][1] === 0 && ranges[1][1] === 1)
  );

  const update = (idx, t, val) => {
    const newVal = [...ranges];
    if (t === 'd') {
      newVal[idx] = [val, newVal[idx][1]];
    } else {
      newVal[idx] = [newVal[idx][0], val];
    }

    updateRanges(newVal);
  };

  const addRange = () => {
    updateRanges([...ranges, [0, 0]]);
  };

  const renderWeighted = () => (
    <div>
      {ranges.map((r, idx) => (
        <div key={idx}>
          <span className={classes.dayRangeLabel}>Days:</span>{' '}
          <TextField
            className={classes.dayRangeParam}
            type="number"
            value={r[0]}
            onChange={e => update(idx, 'd', e.target.value)}
          />
          <span className={classes.dayRangeLabel}>Weight:</span>{' '}
          <TextField
            className={classes.dayRangeParam}
            type="number"
            value={r[1]}
            onChange={e => update(idx, 'w', e.target.value)}
          />
        </div>
      ))}
      <div className={classes.dayRangeBtn} onClick={addRange}>
        Add Range
      </div>
    </div>
  );

  const renderUnweightedOne = () => (
    <div className={classes.rulePart}>
      <TextField
        type="number"
        className={classes.ruleParam}
        value={0}
        onChange={e => updateRanges([[parseInt(e.target.value), 0], ...ranges])}
      />
      <span className={classes.rulePart}>to</span>
      <TextField
        type="number"
        className={classes.ruleParam}
        value={ranges[0][0]}
        onChange={e => update(0, 'd', parseInt(e.target.value))}
      />
      <WeightIcon
        className={classes.weightIcon}
        fontSize="small"
        onClick={() => updateRanges([[ranges[0][0], 2.0]])}
      />
      <span className={classes.rulePart}>days ago</span>
    </div>
  );

  const renderUnweightedTwo = () => (
    <div className={classes.rulePart}>
      <TextField
        type="number"
        className={classes.ruleParam}
        value={ranges[0][0]}
        onChange={e => update(0, 'd', parseInt(e.target.value))}
      />
      <span className={classes.rulePart}>to</span>
      <TextField
        type="number"
        className={classes.ruleParam}
        value={ranges[1][0]}
        onChange={e => update(1, 'd', parseInt(e.target.value))}
      />
      <WeightIcon
        className={classes.weightIcon}
        fontSize="small"
        onClick={() => updateRanges([[ranges[0][0], 2.0]])}
      />
      <span className={classes.rulePart}>days ago</span>
    </div>
  );

  if (weighted) {
    return renderWeighted();
  } else if (ranges.length === 1) {
    return renderUnweightedOne();
  }
  return renderUnweightedTwo();
};

const SubRuleItem = SortableElement(
  ({ idx, subrule, processor, updateSubRule, deleteSubRule }) => {
    const elems = [];
    let current = '';
    const reg = RegExp('{([^:]+):([^:]+)(?::([^:]+))?}');
    const classes = useStyles();

    if (!processor) {
      return (
        <div className={classes.subruleItem}>
          <DeleteIcon
            className={classes.deleteIcon}
            fontSize="small"
            onClick={() => deleteSubRule(idx)}
          />
          <CircularProgress />
        </div>
      );
    }

    const splitCurrent = () => {
      if (current) {
        elems.push(
          <span key={current} className={classes.rulePart}>
            {current}
          </span>
        );
        current = '';
      }
    };

    const renderParam = (name, type, options) => {
      const handleChange = v =>
        updateSubRule(idx, {
          ...subrule,
          values: { ...subrule.values, [name]: v }
        });

      switch (type) {
        case 'int':
          return (
            <TextField
              key={name}
              type="number"
              className={classes.ruleParam}
              value={subrule.values[name]}
              onChange={e => handleChange(parseInt(e.target.value))}
            />
          );
        case 'str':
          return (
            <TextField
              key={name}
              className={classes.ruleParam}
              value={subrule.values[name]}
              onChange={e => handleChange(e.target.value)}
            />
          );
        case 'select':
          return (
            <Select
              native
              key={name}
              value={subrule.values[name]}
              className={classes.ruleParamSelect}
              onChange={e => handleChange(e.target.value)}>
              <option value={''}></option>
              {options.split(',').map(o => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Select>
          );
        case 'wdays':
          return (
            <DayRange
              key={name}
              ranges={subrule.values[name]}
              updateRanges={handleChange}
            />
          );
        case 'step':
          return (
            <Select
              native
              key={name}
              value={subrule.values[name] || -1}
              className={classes.ruleParamSelect}
              onChange={e => handleChange(e.target.value)}>
              <option value={0}>Initial</option>
              <option value={-1}>Previous</option>

              {Array(idx)
                .fill()
                .map((_, o) => (
                  <option key={o} value={o + 1}>
                    {subRuleName(o)}
                  </option>
                ))}
            </Select>
          );
        default:
          throw new Error(`Unsupported type ${type}`);
      }
    };

    processor.template.split(' ').forEach(part => {
      const match = part.match(reg);
      if (!match) {
        current += ' ' + part;
      } else {
        splitCurrent();
        elems.push(
          renderParam(match[1], match[2], match.length > 3 ? match[3] : null)
        );
      }
    });

    splitCurrent();

    return (
      <div className={classes.subruleItem}>
        <DeleteIcon
          className={classes.deleteIcon + ' deleteIcon'}
          fontSize="small"
          onClick={() => deleteSubRule(idx)}
        />
        <span className={classes.subRuleLabel}>{subRuleName(idx)}) </span>
        {elems}
      </div>
    );
  }
);

const SubRuleList = SortableContainer(
  ({ rule, processors, newSubRule, updateSubRule, deleteSubRule }) => {
    const classes = useStyles();

    return (
      <div>
        {rule.children.map((subrule, index) => (
          <SubRuleItem
            key={`item-${subrule.key}`}
            index={index}
            idx={index}
            subrule={subrule}
            processor={processors[subrule.id]}
            updateSubRule={updateSubRule}
            deleteSubRule={deleteSubRule}
          />
        ))}
        <Select
          native
          value={''}
          className={classes.newRuleSelect}
          onChange={e => newSubRule(e.target.value)}>
          <option value={''}>Add Subrule</option>
          {Object.values(processors).map(p => (
            <option key={p.id} value={p.id}>
              {p.description}
            </option>
          ))}
        </Select>
      </div>
    );
  }
);

const RuleItem = SortableElement(
  ({ idx, rule, processors, updateRule, deleteRule, copyRule }) => {
    const classes = useStyles();

    const newSubRule = pid => {
      const subRule = {
        values: {},
        id: pid,
        key: Math.round(1000 * Math.random()).toString()
      };
      updateRule(idx, { ...rule, children: [...rule.children, subRule] });
    };

    const updateSubRule = (subRuleIdx, val) => {
      const newChildren = [...rule.children];
      newChildren[subRuleIdx] = val;
      updateRule(idx, { ...rule, children: newChildren });
    };

    const deleteSubRule = subRuleIdx => {
      updateRule(idx, {
        ...rule,
        children: [
          ...rule.children.slice(0, subRuleIdx),
          ...rule.children.slice(subRuleIdx + 1)
        ]
      });
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
      updateRule(idx, {
        ...rule,
        children: arrayMove(rule.children, oldIndex, newIndex)
      });
    };

    const updateType = val => {
      updateRule(idx, { ...rule, type: val });
    };

    return (
      <div className={classes.ruleItem}>
        <DeleteIcon
          className={classes.deleteIcon}
          fontSize="small"
          onClick={() => deleteRule(idx)}
        />
        <CopyIcon
          className={classes.copyIcon}
          fontSize="small"
          onClick={() => copyRule(idx)}
        />
        <span className={classes.ruleIdx}>{idx + 1}</span>
        <Select
          native
          value={rule.type}
          className={classes.ruleParamSelect}
          onChange={e => updateType(e.target.value)}>
          <option value={'select'}>Select</option>
          <option value={'keep'}>Keep</option>
          <option value={'exclude'}>Exclude</option>
        </Select>
        <SubRuleList
          rule={rule}
          processors={processors}
          newSubRule={newSubRule}
          updateSubRule={updateSubRule}
          deleteSubRule={deleteSubRule}
          helperClass="dragger"
          pressDelay={100}
          onSortEnd={onSortEnd}
        />
      </div>
    );
  }
);

const RuleList = SortableContainer(
  ({ rules, processors, updateRule, deleteRule, copyRule }) => {
    return (
      <div>
        {rules.map((rule, index) => (
          <RuleItem
            key={`item-${rule.key}`}
            index={index}
            idx={index}
            rule={rule}
            processors={processors}
            copyRule={copyRule}
            updateRule={updateRule}
            deleteRule={deleteRule}
          />
        ))}
      </div>
    );
  }
);

const BuyBoxField = props => {
  let { value: rules, onChange, save, fieldName } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [bbRulesMap, setBBRulesMap] = useState({});
  const [showJson, setShowJson] = useState(false);
  const [jsonData, setJsonData] = useState('');

  if (
    !rules ||
    (rules.constructor == Object && Object.keys(rules).length === 0)
  ) {
    rules = [];
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(rules, oldIndex, newIndex));
  };

  const updateRule = (ruleIdx, newValue) => {
    const newRules = [...rules];
    newRules[ruleIdx] = newValue;
    // setRules(newRules);
    onChange(newRules);
  };

  const copyRule = ruleIdx => {
    const newRule = {
      ...rules[ruleIdx],
      key: Math.round(1000 * Math.random()).toString()
    };

    onChange([...rules.slice(0, ruleIdx), newRule, ...rules.slice(ruleIdx)]);
  };

  const deleteRule = ruleIdx =>
    onChange([...rules.slice(0, ruleIdx), ...rules.slice(ruleIdx + 1)]);

  const addRule = () => {
    const processor = {
      type: 'select',
      children: [],
      key: Math.round(1000 * Math.random()).toString()
    };
    onChange([...rules, processor]);
  };

  const reload = () => {
    api
      .get('/buyboxrules')
      .then(({ data }) => {
        setBBRulesMap(
          data.reduce((m, c) => {
            m[c.id] = c;
            return m;
          }, {})
        );
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  useEffect(reload, []);

  const onEditorChange = code => {
    setJsonData(code);
    try {
      onChange(JSON.parse(code));
    } catch (e) {}
  };

  const changeMode = () => {
    if (showJson) {
      setShowJson(false);
    } else {
      setJsonData(JSON.stringify(rules, null, 2));
      setShowJson(true);
    }
  };

  return (
    <Container className={classes.root}>
      <div className={classes.toolbar}>
        <span className={classes.ruleName}>{fieldName}</span>
        <span className={classes.spacer} />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={addRule}>
          Add Rule
        </Button>
        {save !== undefined && (
          <Button className={classes.button} variant="contained" onClick={save}>
            Save
          </Button>
        )}
      </div>

      <Paper className={classes.content}>
        <PerfectScrollbar className={classes.content}>
          <div className={classes.inner}>
            <CodeIcon className={classes.modeSwitchIcon} onClick={changeMode} />
            {!showJson && (
              <RuleList
                rules={rules}
                processors={bbRulesMap}
                onSortEnd={onSortEnd}
                updateRule={updateRule}
                deleteRule={deleteRule}
                copyRule={copyRule}
                helperClass="dragger"
                pressDelay={100}
              />
            )}
            {showJson && (
              <Editor
                value={jsonData}
                onValueChange={code => onEditorChange(code)}
                highlight={code => highlight(code, languages.json)}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12
                }}
              />
            )}
          </div>
        </PerfectScrollbar>
      </Paper>
    </Container>
  );
};

export default BuyBoxField;
