import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import api from 'api-snej';
import ConfirmDialog from 'components/ConfirmDialog';
import { useChannel } from 'push';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  frame: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  root: {},
  content: {
    padding: '20px'
  },
  createCard: {
    padding: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  chipSuccess: {
    background: green[400],
    color: 'white'
  },
  chipProgress: {
    // background: red[400],
    // color: 'white',
    width: '58px',
    textAlign: 'center'
  },
}));

const SheetsPage = () => {
  const classes = useStyles();

  const [toBeDel, setToBeDel] = useState(null);

  const [name, setName] = useState('');
  const [selectedWorksheet, setSelectedWorksheet] = useState('');
  const [tasks, setTasks] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useChannel('sheets:task_update', ({ task }) => {
    let found = false;
    const tt = [...tasks];
    for (let i = 0; i < tt.length; i++) {
      const o = tt[i];
      if (o.tid == task.tid) {
        tt[i] = task;
        found = true;
        break;
      }
    }
    if (!found) {
      tt.push(task);
      tt.sort((a, b) => b.created_at - a.created_at);
    }
    setTasks(tt);
  });
  useChannel('sheets:all_tasks', ({ tasks }) => {
    const tt = [...tasks];
    tt.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
    setTasks(tt);
  });

  useEffect(() => {
    (async () => {
      const wRes = await api.get('/worksheets');
      setWorksheets(wRes.data.worksheets);
      if (wRes.data.worksheets.length > 0) {
        setSelectedWorksheet(wRes.data.worksheets[0]);
      }
      const tRes = await api.get('/tasks/sheets');
      setTasks(tRes.data.tasks);
    })();
  }, []);

  const create = async () => {
    await api.post('/tasks/sheets', { name, sheet: selectedWorksheet.sheet, worksheet: selectedWorksheet.worksheet });
  };
  const performDelete = async () => {
    await api.delete(`/tasks/sheets/${toBeDel.tid}`);
    setToBeDel(null);
  };
  const deleteItem = (item) => {
    setToBeDel(item);
  };

  return (
    <div className="p-8">
      <ConfirmDialog
        open={toBeDel != null}
        onCancel={() => setToBeDel(null)}
        onConfirm={performDelete}
        title="Delete Product Set?"
        text={`Are you sure you want to delete product set '${
          toBeDel ? toBeDel.name : ''
        }'?`}
      />
      <div>
        <Card className="p-5">
          <CardContent>
            <TextField
              style={{ marginRight: '30px' }}
              // helperText="Please specify the first name"
              label="Name"
              margin="dense"
              onChange={(e) => setName(e.target.value)}
              value={name}
              variant="outlined"
              className="w-80"
            />
            <FormControl
              className={classes.formControl}
              style={{ marginRight: '30px' }}>
              <Select
                labelId="table-select-label"
                id="demo-simple-select"
                value={selectedWorksheet}
                onChange={(e) => setSelectedWorksheet(e.target.value)}
                className="w-48">
                {worksheets.map((w) => (
                  <MenuItem value={w} key={`${w.sheet} - ${w.worksheet}`}>
                    {`${w.sheet} / ${w.worksheet}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{ marginRight: '30px' }}
              className="float-right"
              variant="contained"
              color="primary"
              onClick={create}
              disabled={createLoading}>
              Search
            </Button>
            {createLoading && <CircularProgress size={24} />}
          </CardContent>
        </Card>
        <Card className="mt-5">
          <CardContent>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Success</TableCell>
                      <TableCell>Failed</TableCell>
                      <TableCell>Avg Time</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.map((item) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={item.tid}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            className={
                              item.done === item.total
                                ? classes.chipSuccess
                                : classes.chipProgress
                            }
                            label={
                              item.done === item.total
                                ? '100%'
                                : `${Math.round(
                                    (100 * item.done) / item.total
                                  )}%`
                            }
                          />
                        </TableCell>
                        <TableCell>{item.total}</TableCell>
                        <TableCell>{item.done - (item.fail || 0)}</TableCell>
                        <TableCell>{item.fail || 0}</TableCell>
                        <TableCell>{Math.round((item.time || 0) / item.done || 1)}</TableCell>
                        <TableCell>
                          <DeleteIcon
                            className={
                              'text-gray-400 hover:text-red-400 cursor-pointer'
                            }
                            onClick={() => deleteItem(item)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            {isLoading && <CircularProgress size={24} />}
          </CardActions>
        </Card>
      </div>
    </div>
  );
  // return <iframe src="https://snej.hadi.sh" className={classes.frame}></iframe>;
};

export default SheetsPage;
